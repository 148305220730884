/**
 * web/typo3conf/ext/neufferfrontend/Resources/Private/Js/app.ts
 */

export enum Breakpoint {
    XS = 560,
    SM = 768,
    MD = 992,
    LG = 1200,
}

class HDEApp {
    public setBrowserHistoryHash(hash: string) {
        window.location.hash = hash;
    }

    public viewportIsSmallerThanBreakpoint(breakpoint: Breakpoint) {
        return window.innerWidth < breakpoint;
    }

    public isTouchDevice() {
        return ('ontouchstart' in window) || (window.navigator.maxTouchPoints > 0);
    }
}

export const hdeApp = new HDEApp();
